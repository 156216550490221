import { init as emailjsInit, send as emailjsSend, sendForm as emailjsSendForm, EmailJSResponseStatus, } from '@emailjs/browser';
import { createReactNativeStorage } from './utils/createReactNativeStorage/createReactNativeStorage';
import { createLocation } from './utils/createLocation/createLocation';
/**
 * EmailJS global SDK config
 * @param {object} options - the EmailJS global SDK config options
 * @param {string} origin - the non-default EmailJS origin
 */
const init = (options, origin) => {
    return emailjsInit({
        storageProvider: createReactNativeStorage(),
        ...options,
    }, origin);
};
/**
 * Send a template to the specific EmailJS service
 * @param {string} serviceID - the EmailJS service ID
 * @param {string} templateID - the EmailJS template ID
 * @param {object} templateParams - the template params, what will be set to the EmailJS template
 * @param {object} options - the EmailJS SDK config options
 * @returns {Promise<EmailJSResponseStatus>}
 */
const send = async (serviceID, templateID, templateParams, options) => {
    createLocation();
    return emailjsSend(serviceID, templateID, templateParams, {
        storageProvider: createReactNativeStorage(),
        ...options,
    });
};
/**
 * Send a form the specific EmailJS service
 * @param {string} serviceID - the EmailJS service ID
 * @param {string} templateID - the EmailJS template ID
 * @param {string | HTMLFormElement} form - the form element or selector
 * @param {object} options - the EmailJS SDK config options
 * @returns {Promise<EmailJSResponseStatus>}
 */
const sendForm = async (serviceID, templateID, form, options) => {
    createLocation();
    return emailjsSendForm(serviceID, templateID, form, {
        storageProvider: createReactNativeStorage(),
        ...options,
    });
};
export { init, send, sendForm, EmailJSResponseStatus };
export default {
    init,
    send,
    sendForm,
    EmailJSResponseStatus,
};
